import resolveImage from "./resolveImage";

const resolvePageBuilder = blocks =>
  blocks.map(block => {
    const { uid, type } = block;
    switch (type) {
      case "articleGrid":
        return {
          uid,
          type,
          articles: block.articles?.map(a => {
            return {
              ...a,
              descriptor: a.descriptor || a.metaDescription,
              image: resolveImage(a.image || a.metaImage),
            };
          }),
          filters: block.filters?.map(f => f.uid),
        };
      case "benefitList":
        return {
          uid,
          type,
          heading: block.heading,
          copy: block.copy,
          button: block.button,
          benefits: block.benefits,
        };
      case "buttonList":
        return {
          uid,
          type,
          buttons: block.buttons.map(b => ({ ...b.button, uid: b.uid })),
        };
      case "callToAction":
        return {
          uid,
          type,
          color: block.color,
          icon: block.icon,
          heading: block.heading,
          descriptor: block.descriptor,
          button: block.button,
        };
      case "callToActionGrid":
        return {
          uid,
          type,
          ctas: block.ctas,
        };
      case "cardCopy":
        return {
          uid,
          type,
          color: block.color,
          copy: block.copy,
        };
      case "cardGrid":
        return {
          uid,
          type,
          horizontal: block.horizontal,
          cards: block.cards,
        };
      case "careerList":
        return {
          uid,
          type,
          color: block.color,
          heading: block.heading,
          copy: block.copy,
          link: block.link,
          careers: block.careers,
        };
      case "caseStudyGrid":
        return {
          uid,
          type,
          caseStudies: block.caseStudies?.map(s => ({
            ...s,
            heading: s.heading || s.metaDescription,
            image: resolveImage(s.metaImage),
          })),
          columnCount: block.columnCount,
          filters: block.filters?.map(f => f.uid),
        };
      case "circleGrid":
        return {
          uid,
          type,
          circles: block.circles,
        };
      case "columnCopy":
        return {
          uid,
          type,
          columns: block.columns,
        };
      case "copy":
        return {
          uid,
          type,
          copy: block.copy,
        };
      case "details":
        return {
          uid,
          type,
          details: block.details,
        };
      case "featureColumns":
        return {
          uid,
          type,
          features: block.features,
        };
      case "featureHighlight":
        return {
          uid,
          type,
          color: block.color,
          heading: block.heading,
          descriptor: block.descriptor,
          benefits: block.benefits,
          graphic: resolveImage(block.graphic),
        };
      case "form":
        return {
          ...block,
          uid,
          type,
        };
      case "highlightedText":
        return {
          uid,
          type,
          ...block,
        };
      case "iconGrid":
        return {
          uid,
          type,
          icons: block.gridItems
            .filter(i => i.icon[0])
            .map(i => ({ ...i.icon[0], label: i.label })),
        };
      case "imageColumns":
        return {
          uid,
          type,
          columns: block.columns?.map(col => {
            return {
              ...col,
              image: resolveImage(col.image),
            };
          }),
        };
      case "imageCopy":
        return {
          uid,
          type,
          heading: block.heading,
          copy: block.copy,
          button: block.button,
          image: resolveImage(block.image),
          crop: block.crop,
        };
      case "impactGrid":
        return {
          uid,
          type,
          content: block.gridItems.map(c => ({
            ...c,
            heading: c.heading || c.metaDescription,
            image: resolveImage(c.image),
          })),
        };
      case "modulesOverview":
        return {
          ...block,
          image: resolveImage(block.image),
          uid,
          type,
        };
      case "organizationGrid":
        return {
          uid,
          type,
          ...block,
          organizations: block.organizations.map(org => ({
            ...org,
            logo: resolveImage(org.logo),
          })),
        };
      case "sectionHeading":
        return {
          uid,
          type,
          heading: block.heading,
          descriptor: block.descriptor,
          button: block.button,
        };
      case "solutionBuilderLinks":
        return {
          uid,
          type,
          byo: {
            title: block.title,
            descriptor: block.descriptor,
            icon: block.icon?.[0]?.code,
            cta: block.cta,
          },
        };
      case "solutionsCarousel":
        return {
          uid,
          type,
          title: block.title,
          descriptor: block.descriptor,
          url: block.url,
          solutions: block.solutions,
        };
      case "solutionsOverview":
        return {
          uid,
          type,
          heading: block.heading,
          descriptor: block.descriptor,
          solutions: block.solutionsCategories.map(s => ({
            uid: s.uid,
            heading: s.heading,
            copy: s.copy,
            benefits: s.benefits.map(b => b.col1),
            image: resolveImage(s.image),
            links: s.links,
          })),
        };
      case "solutionWheel":
        return {
          uid,
          type,
          label: block.label,
        };
      case "stats":
        return {
          uid,
          type,
          stats: block.stats,
        };
      case "testimonialCarousel":
        return {
          uid,
          type,
          testimonials: block.testimonials.map(s => ({
            ...s,
            image: resolveImage(s.image),
          })),
        };
      case "twoColumnCopy":
        return {
          uid,
          type,
          heading: block.heading,
          copy: block.copy,
          image: resolveImage(block.image),
        };
      case "utilityAnchorLink":
        return {
          uid,
          type,
          ...block,
        };
      case "utilityBackgroundColor":
        return {
          uid,
          type,
          ...block,
        };
      case "video":
        return {
          uid,
          type,
          ...block,
        };
      default:
        return block;
    }
  });
export default resolvePageBuilder;
