/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/CaseStudyPage";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
import resolveImage from "@dataResolvers/resolveImage";
import resolveCaseStudy from "@dataResolvers/resolveCaseStudy";
// eslint-disable-next-line no-unused-vars
import PageBuilderFragment from "@fragments/PageBuilderFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query CaseStudiesDefaultQuery($uri: [String]) {
    craft {
      entry(section: "caseStudies", uri: $uri) {
        ... on Craft_caseStudies_default_Entry {
          uid
          ancestors {
            uid
            title
            uri
          }
          # overview
          audiences {
            title
            uid
          }
          copy: copy0
          modules: moduleList {
            ... on Craft_modules_default_Entry {
              uid
              title
              url
              descriptor: heading0
              icon {
                ... on Craft_icons_default_Entry {
                  code: code0
                }
              }
              color
            }
          }
          # pageBuilder
          pageBuilder {
            ...PageBuilderFragment
          }
          # meta
          title
          url
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entries(section: "caseStudies", limit: 3) {
        ... on Craft_caseStudies_default_Entry {
          uid
          url: uri
          type: sectionHandle
          title
          heading: heading0
          metaDescription
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries }) => {
  const {
    uid,
    // overview
    ancestors,
    audiences,
    copy,
    modules,
    // pageBuilder
    pageBuilder,
    // meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
  } = entry;

  const caseStudies = entries
    .map(resolveCaseStudy)
    .filter(e => e.uid !== entry.uid);

  return {
    overview: {
      crumbs: [
        ...ancestors,
        {
          uid: "impact",
          uri: "/impact",
          title: "Impact",
        },
        {
          uid: "caseStudies",
          uri: "/impact/case-studies",
          title: "Case Studies",
        },
        {
          uid,
          title: "Case Study",
        },
      ],
      heading: title,
      image: resolveImage(metaImage),
      audiences,
      copy,
      modules,
    },
    pageBuilder: resolvePageBuilder(pageBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
    },
    caseStudies,
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="CaseStudiesDefaultQuery"
    {...props}
  />
);

export default Page;
