import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const Overview = loadable(() => import("@organisms/Overview"));
const PageBuilder = loadable(() => import("@templates/PageBuilder"));
const RelatedCaseStudies = loadable(() =>
  import("@organisms/RelatedCaseStudies")
);
const NewsletterSignup = loadable(() => import("@organisms/NewsletterSignup"));

const CaseStudyPage = ({ data }) => {
  const { overview, pageBuilder, caseStudies } = data;
  return (
    <PageContainer overflow>
      <Overview {...overview} />
      <PageBuilder blocks={pageBuilder} hasHero={!!overview.heading} />
      <RelatedCaseStudies caseStudies={caseStudies} />
      <NewsletterSignup />
    </PageContainer>
  );
};

export default CaseStudyPage;
