import resolveImage from "./resolveImage";

const resolveCaseStudy = caseStudy => {
  const { heading, metaDescription, metaImage } = caseStudy;

  return {
    ...caseStudy,
    heading: heading || metaDescription,
    image: resolveImage(metaImage),
  };
};

export default resolveCaseStudy;
